
export function getQuestions(callback) {
    return [
        'A je zhvillues?',
        2000,
        callback,
        'A je zhvillues lojrash?',
        1300,
        callback,
        'A je zhvillues i frontend-it?',
        1900,
        callback,
        'A je zhvillues i frontend-it, apo backend-it?',
        1300,
        callback,
        'A je projekt menaxher?',
        2300,
        callback,
        'A je projekt menaxher, apo HR?',
        1800,
        callback,
        'A je dizajner?',
        1800,
        callback,
        'A bene muzike ti?',
        2400,
        callback,
        'A je tester softuerik?',
        1700,
        callback,
        'A je administrator sistemesh?',
        1700,
        callback,
        'A je hulumtues shkencor?',
        1700,
        callback,
        'A je inxhinier i rrjetave?',
        1300,
        callback,
    ]
}